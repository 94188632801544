@font-face {
  font-family: "DMSans";
  font-weight: normal;
  src: local("DMSans-Regular"),
    url(./fonts/DMSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "DMSans";
  font-weight: bold;
  src: local("DMSans-Bold"), url(./fonts/DMSans-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "DMSans";
  font-weight: medium;
  src: local("DMSans-Medium"), url(./fonts/DMSans-Medium.ttf) format("truetype");
}

body {
  background-color: #121212;
  background-image: url("./images/bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-size: 1.5em;
  color: #ffffff;
  font-family: DMSans;
  font-weight: normal;
  height: 100%;
  width: 100%;
}

.logo {
  font-weight: medium;
  font-size: 2em;
  margin-top: 0.25em;
  color: #ffffff;
}

.dashboardTitle {
  padding: 0.2em;
  padding-left: 0.5em;
  margin-top: 1em;
  font-size: 0.8em;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 0.2em;
  font-weight: bold;
}

.dashboardContentRow {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 0.1em;
  padding-right: 0.1em;
  font-weight: normal;
  font-size: 0.8em;
}

.dashboardContentValue {
  text-align: right;
  font-weight: normal;
  font-size: 0.9em;
}
